var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b7e74f421d4f734f2e44a72bdffa70e2c6de1d60"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable no-undef */
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import pkjson from './package.json'
// eslint-disable-next-line no-undef
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN

process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' &&
  Sentry.init({
    dsn: SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.01,
    publishRelease: process.env.NEXT_PUBLIC_SENTRY_PUBLISH_RELEASE,
    attachCommits: process.env.NEXT_PUBLIC_SENTRY_AUTO_ATTACH_COMMITS,
    // config: {
    //   environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    //   release:
    //     `${process.env.NEXT_PUBLIC_SENTRY_PROJECT}@` +
    //     process.env.NEXT_PUBLIC_APP_VERSION
    // },
    release: `${process.env.NEXT_PUBLIC_SENTRY_PROJECT}@${pkjson.version}`,
    // debug: true,
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    debug: false

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    // integrations: [
    //   new Sentry.Replay({
    //     // Additional Replay configuration goes in here, for example:
    //     maskAllText: false,
    //     blockAllMedia: false
    //   })
    // ]
  })
