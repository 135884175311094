/* eslint-disable no-undef */
import React from 'react'
import Head from 'next/head'
// eslint-disable-next-line no-unused-vars
import styles from '../styles/global.scss'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import { ResponsiveProvider } from '@farfetch/react-context-responsive'
import { useEffect, useState } from 'react'
import { SearchContextProvider } from '../providers/SearchContext'
import { FavouritesContextProvider } from '../providers/favouritesProvider'
import { CurrencyContextProvider } from '../providers/currencyProvider'
import { ModalContextProvider } from '../providers/modalProvider'
import { AuthContextProvider } from '../providers/authProvider'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/ne'

import LoadingPage from '../components/templates/LoadingPage'
import axios from '/components/configs/axios'
import { useRouter } from 'next/router'

const MyApp = ({ Component, pageProps }) => {
  // const [loaded, setLoaded] = useState(false)
  const [maintenance, setMaintenance] = useState(false)
  const router = useRouter()

  const breakpoints = {
    xs: '640px',
    sm: '768px',
    md: '1050px',
    lg: '1250px',
    xl: '1551px'
  }

  const breakpointsMax = {
    xs: '641px',
    sm: '767px',
    md: '1049px',
    lg: '1249px',
    xl: '1550px'
  }

  useEffect(() => {
    moment.locale(router.locale)
  }, [router.locale])

  useEffect(() => {
    axios.get('maintenance').then((res) => {
      setMaintenance(res.data.isUnderMaintenance)
    })
  }, [])

  return (
    <>
      <Head>
        <link rel="dns-prefetch" href={process.env.NEXT_PUBLIC_ASSETS_URL} />
        <link rel="dns-prefetch" href={process.env.NEXT_PUBLIC_API_URL} />

        <link rel="preconnect" href={process.env.NEXT_PUBLIC_ASSETS_URL} />
        <link rel="preconnect" href={process.env.NEXT_PUBLIC_API_URL} />
        <link rel="icon" href="/manifest/favicon.ico" />
        <link
          rel="manifest"
          href="/manifest/manifest.json"
          crossOrigin="use-credentials"
        />
        <meta property="og:type" content="website" />

        <meta
          property="og:url"
          content={
            typeof window !== 'undefined' ? window.location.href : undefined
          }
        />

        <meta
          name="msapplication-config"
          content="/manifest/browserconfig.xml"
        />

        {typeof window !== 'undefined' &&
          process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && (
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `
    adroll_adv_id = "${process.env.NEXT_PUBLIC_ADROLL_ADV_ID}";
    adroll_pix_id = "${process.env.NEXT_PUBLIC_ADROLL_PIX_ID}";
    adroll_version = "2.0";

    (function(w, d, e, o, a) {
        w.__adroll_loaded = true;
        w.adroll = w.adroll || [];
        w.adroll.f = [ 'setProperties', 'identify', 'track' ];
        var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id
                + "/roundtrip.js";
        for (a = 0; a < w.adroll.f.length; a++) {
            w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
                return function() {
                    w.adroll.push([ n, arguments ])
                }
            })(w.adroll.f[a])
        }

        e = d.createElement('script');
        o = d.getElementsByTagName('script')[0];
        e.async = 1;
        e.src = roundtripUrl;
        o.parentNode.insertBefore(e, o);
    })(window, document);
    adroll.track("pageView");`
              }}
              strategy="beforeInteractive"
            />
          )}
      </Head>

      {process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && (
        <GoogleAnalytics
          gaMeasurementId={process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}
          trackPageViews
        />
      )}

      <CurrencyContextProvider>
        <AuthContextProvider>
          <SearchContextProvider>
            <FavouritesContextProvider>
              <ModalContextProvider>
                <ResponsiveProvider
                  breakpoints={breakpoints}
                  breakpointsMax={breakpointsMax}
                >
                  {maintenance ? (
                    <LoadingPage
                      title="Maintenance in Progress"
                      subtitle="We're working hard to improve your experience. Please bear with us as we enhance our site to serve you better. We'll be back online shortly!"
                      spinner={false}
                    />
                  ) : (
                    <Component {...pageProps} />
                  )}
                </ResponsiveProvider>
              </ModalContextProvider>
            </FavouritesContextProvider>
          </SearchContextProvider>
        </AuthContextProvider>
      </CurrencyContextProvider>
    </>
  )
}

export default MyApp
