/* eslint-disable react/prop-types */
import classNames from 'classnames'

const Container = ({ children, className, variant }) => {
  const containerVariant = variant ? variant : []

  return (
    <div className={classNames('container', className, ...containerVariant)}>
      {children}
    </div>
  )
}

export default Container
