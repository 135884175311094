import React, { createContext, useContext } from 'react'
import { useState, useEffect } from 'react'

const AuthContext = createContext('default')

const useAuthContext = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useModalContext was used outside of its Provider')
  }

  return context
}

const AuthContextProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [isAgent, seIsAgent] = useState(false)
  const [user, setUser] = useState(false)
  let isAuthorized = loggedIn && isAgent

  useEffect(() => {
    // When storage changes refetch
    setLoggedIn(localStorage.getItem('access_token') || false)
    seIsAgent(localStorage.getItem('isAgent') || false)
    setUser(localStorage.getItem('user') || false)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        isAgent,
        seIsAgent,
        isAuthorized,
        user,
        setUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { useAuthContext, AuthContext, AuthContextProvider }
