import React, { createContext, useContext } from 'react'
import { useState } from 'react'

const CurrencyContext = createContext('default')

const useCurrencyContext = () => {
  const context = useContext(CurrencyContext)

  if (context === undefined) {
    throw new Error('useCurrencyContext was used outside of its Provider')
  }

  return context
}

const CurrencyContextProvider = ({ children }) => {
  const defaultValue = 'usd'
  const [currency, setCurrency] = useState(defaultValue)
  const currencies = [
    { value: 'usd', label: '$ USD', coin: '$' },
    { value: 'eur', label: '€ EUR', coin: '€' }
    // { value: 'cad', label: '$ CAD' },
    // { value: 'gbp', label: '£ GBP' }
  ]

  const selectedCurrency = currencies.find(function (item) {
    return item.value === currency
  })

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        setCurrency,
        currencies,
        defaultValue,
        selectedCurrency
      }}
    >
      {children}
    </CurrencyContext.Provider>
  )
}

export { useCurrencyContext, CurrencyContext, CurrencyContextProvider }
