import React, { createContext, useContext } from 'react'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

const ModalContext = createContext('default')

const useModalContext = () => {
  const context = useContext(ModalContext)

  if (context === undefined) {
    throw new Error('useModalContext was used outside of its Provider')
  }

  return context
}

const ModalContextProvider = ({ children }) => {
  const [show, setShow] = useState(false)
  const router = useRouter()
  const [content, setContent] = useState(false)
  const [variant, setVariant] = useState([])
  const [beforeClose, handleBeforeClose] = useState(false)
  const body = typeof window !== 'undefined' && document.querySelector('body')

  const handleShowModal = () => {
    setShow(true)
    body.classList.add('modal-open')
  }
  const handleCloseModal = () => {
    setShow(false)
    body.classList.remove('modal-open')
  }

  useEffect(() => {
    if (show) {
      beforeClose && beforeClose()
      handleCloseModal()
    }
  }, [router.pathname])

  return (
    <ModalContext.Provider
      value={{
        handleShowModal,
        handleCloseModal,
        handleBeforeClose,
        setContent,
        content,
        setVariant,
        show,
        variant
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export { useModalContext, ModalContext, ModalContextProvider }
