import React, { createContext, useContext, useEffect, useState } from 'react'
import pull from 'lodash-es/pull'
import axios from '/components/configs/axios'
import { useAuthContext } from './authProvider'
import { isEmpty } from 'lodash-es'

const FavouritesContext = createContext('default')

const useFavouritesContext = () => {
  const context = useContext(FavouritesContext)

  if (context === undefined) {
    throw new Error('useCurrencyContext was used outside of its Provider')
  }

  return context
}

const FavouritesContextProvider = ({ children }) => {
  const [favourites, setFavourites] = useState([])
  const { user, loggedIn } = useAuthContext()
  function isFavourite(value) {
    return favourites.includes(value)
  }

  function saveFavouritesInDB() {
    axios
      .post('user/favourites/update', {
        user_id: user,
        favourites
      })
      .catch((err) => console.error(err))
  }

  function getFavouritesInDB() {
    axios
      .get(`user/${user}/favourites`)
      .then((res) => {
        setFavourites(res.data.favourites)
        localStorage.setItem('favourites', res.data.favourites)
      })
      .catch((err) => console.error(err))
  }

  function addOrRemoveFavourite(value) {
    let newFavourites = [...favourites]

    if (isFavourite(value)) {
      pull(newFavourites, value)
    } else {
      newFavourites.push(value)
    }

    setFavourites(newFavourites)
    localStorage.setItem('favourites', favourites)

    if (loggedIn) {
      saveFavouritesInDB()
    }
  }

  function refreshFavourites() {
    if (loggedIn) {
      if (!isEmpty(favourites)) {
        saveFavouritesInDB()
      }
      getFavouritesInDB()
    } else if (typeof localStorage !== 'undefined') {
      localStorage.getItem('favourites') !== '' &&
        setFavourites(localStorage.getItem('favourites')?.split(',') || [])
    }
  }

  useEffect(() => {
    refreshFavourites()
  }, [loggedIn])

  return (
    <FavouritesContext.Provider
      value={{
        favourites,
        isFavourite,
        addOrRemoveFavourite
      }}
    >
      {children}
    </FavouritesContext.Provider>
  )
}

export { useFavouritesContext, FavouritesContext, FavouritesContextProvider }
