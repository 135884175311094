import axios from 'axios'
const instance = axios.create({
  // .. where we make our configurations
  // eslint-disable-next-line no-undef
  baseURL: process.env.NEXT_PUBLIC_API_URL
})
instance.defaults.headers.common[
  'Authorization'
  // eslint-disable-next-line no-undef
] = `Bearer ${process.env.NEXT_PUBLIC_API_AUTH_TOKEN}`

export default instance
