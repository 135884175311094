export const withModifiers = (base, modifiers, styles) => {
  let classNames = styles[base]
  if (!modifiers || !Array.isArray(modifiers) || 0 === modifiers.length) {
    return classNames
  }
  modifiers.forEach((modifier) => {
    if (styles[base + '--' + modifier]) {
      classNames += ' ' + styles[base + '--' + modifier]
    }
  })
  return classNames
}
