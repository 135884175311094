import Container from '../../elements/Container'
import Spinner from '../../elements/Spinner'
import styles from './styles.module.scss'
import { useResponsive } from '@farfetch/react-context-responsive'

const LoadingPage = ({
  title = 'Loading',
  subtitle = false,
  spinner = true
}) => {
  const responsive = useResponsive()

  return (
    <div className={styles.page}>
      <Container variant={['-xs2']}>
        <h1>{title}</h1>

        {subtitle && <p>{subtitle}</p>}
        {spinner && (
          <div className={styles.page__spinner}>
            <Spinner variant={[!responsive.lessThan.md && 'xl', 'darker']} />
          </div>
        )}
      </Container>
    </div>
  )
}

export default LoadingPage
